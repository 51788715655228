@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Nanum Myeongjo', serif;
}

.app {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-color: #000;
  font-family: 'Nanum Myeongjo', serif;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(5px);
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-family: 'Nanum Myeongjo', serif;
}

.centered-text h2 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.footer {
  position: fixed;
  bottom: 0;
  text-align: center;
  margin-left: 25%;
  margin-bottom: 10%;
  width: 50%;
  height: 25px;
  background-color: transparent;
  color: #fff;
  font-size: 1rem;
  overflow: hidden;
}

.footer marquee {
  width: 100%;
  height: 100%;
}

.enter-button {
  font-size: 1.0rem;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 2rem;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Nanum Myeongjo', serif;
}
